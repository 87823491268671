<template>
  <v-card
    class="card-checked"
    :class="{'card-small': small}">
    <v-toolbar
      :color="stateCheckedColor"
      flat>
      <v-avatar
        color="white"
        :size="sizeAvatar">
        <v-icon
          :color="stateCheckedColor"
          :size="sizeIcon"
          light>
          {{ icon }}
        </v-icon>
      </v-avatar>
    </v-toolbar>

    <v-card-text>
      <v-toolbar-title class="darkGray--text text-center mx-0">
        {{ title }}
      </v-toolbar-title>

      <div :style="setStyle">
        <slot />
      </div>
    </v-card-text>
    <v-divider />

    <v-card-actions class="px-3">
      <v-switch
        v-model="isChecked"
        class="ma-0 pa-0"
        :color="color"
        :disabled="displayMode"
        :label="stateCheckedLabel"
        :hide-details="true"
        @change="change" />
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    props: {
      small: {
        type: Boolean,
        default: false
      },
      checked: {
        type: Boolean,
        default: false
      },
      color: {
        type: String,
        default: 'accent'
      },
      noLabel: {
        type: Boolean,
        default: false
      },
      icon: {
        type: String,
        default: '',
        require: true
      },
      title: {
        type: String,
        default: '',
        require: true
      },
      minHeight: {
        type: String,
        default: '1px'
      },
      displayMode: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        isChecked: this.checked
      }
    },
    computed: {
      stateCheckedLabel () {
        if (this.noLabel) {
          return ''
        } else {
          return this.isChecked ? 'Ativado' : 'Desativado'
        }
      },
      stateCheckedColor () {
        return this.isChecked ? this.color : 'grayLigth'
      },
      setStyle () {
        return { 'min-height': this.minHeight }
      },
      sizeAvatar () {
        return this.small ? '40px' : '60px'
      },
      sizeIcon () {
        return this.small ? '24px' : '36px'
      }
    },
    watch: {
      checked (result) {
        this.isChecked = result
      }
    },
    methods: {
      change (isChecked) {
        this.isChecked = isChecked
        this.$emit('change', isChecked)
      }
    }
  }
</script>

<style lang="sass">
  .card-checked
    height: 100% !important
    display: flex
    flex-direction: column

    .v-toolbar__content
      height: 60px

    &.card-small
      .v-toolbar__content
        height: 35px!important

      .v-card__text
        height: 40px
        padding-bottom: 30px
        padding-top: 40px

      .v-toolbar__title
        font-size: 16px

    .v-divider
      flex: 0

    .v-card__text
      padding-top: 50px
      flex: 2 0 auto

    .v-card__actions
      .v-input__control,
      .v-input__slot
        width: 100%

      .v-input__slot
        justify-content: space-between

      .v-label
        text-align: right
        font-size: 14px

    .v-avatar
      position: absolute
      bottom: -35px
      left: 50%
      transform: translateX(-50%)
      box-shadow: 0px 0px 0px 4px $bgColor, 0px 0px 0px 8px $light

    .v-switch
      .v-input-group__details
        display: none

    .v-expansion-panel__header
      padding: 0
      align-items: baseline

</style>
