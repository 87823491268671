<template>
  <v-container
    grid-list-lg
    class="pt-6">
    <a
      class="back-link"
      @click="$router.push({ name: 'CaseChannelsList' })">
      voltar para a lista <v-icon small>{{ $root.icons.action.previous }}</v-icon>
    </a>
    <v-layout class="mt-6">
      <v-flex
        xs4
        sm4>
        <card-checked
          title="E-mail"
          color="channelEmail"
          :icon="$root.icons.channel.email"
          :checked="emailChecked"
          no-label
          small
          @change="changeEmail" />
      </v-flex>
      <v-flex
        xs4
        sm4>
        <card-checked
          title="SMS"
          color="channelSms"
          :icon="$root.icons.channel.sms"
          :checked="smsChecked"
          no-label
          small
          @change="changeSms" />
      </v-flex>
      <v-flex
        xs4
        sm4>
        <card-checked
          title="Carta"
          color="channelLetter"
          :icon="$root.icons.channel.letter"
          :checked="letterChecked"
          no-label
          small
          @change="changeLetter" />
      </v-flex>
    </v-layout>

    <v-form
      v-if="emailChecked"
      ref="formEmail"
      v-model="validEmail"
      lazy-validation>
      <v-layout
        row
        wrap>
        <v-flex
          xs12
          sm12>
          <select-emails
            label="Email"
            data-cy="userEmail"
            :items="emails"
            :value="newEmail"
            :rules="emailRules"
            @change="setEmail" />
        </v-flex>
        <v-flex
          xs12
          sm12>
          <v-btn
            title="Enviar um novo convite por e-mail"
            color="accent"
            :disabled="!validEmail"
            :loading="isLoading"
            outlined
            block
            depressed
            @click="submitEmail">
            Enviar e-mail
          </v-btn>
        </v-flex>
      </v-layout>
    </v-form>

    <v-form
      v-if="smsChecked"
      ref="formSms"
      v-model="validSms"
      lazy-validation>
      <v-layout
        row
        wrap>
        <v-flex
          xs12
          sm12>
          <v-layout
            v-for="(phone, index) in newPhones"
            :key="`phone-${index}`"
            class="layout-block"
            row
            wrap>
            <v-flex
              xs12
              sm12>
              <field-phone
                label="Número"
                :index="index"
                :options="phones"
                multiple
                no-type
                @change="setPhoneNumber" />
            </v-flex>
            <!-- <v-flex xs12 sm4>
              <v-tooltip top v-if="showRemoveAction(index)">
                <v-btn  fab small v-on="on" class="elevatiov-n-0 mini" @click="removePhone(index)">
                  <v-icon small dark color="error">{{$root.icons.action.remove}}</v-icon>
                </v-btn>
                <span>Remover telefone</span>
              </v-tooltip>
              <v-tooltip top v-if="showAddAction(index)">
                <v-btn fab small v-on="on" class="elevatiov-n-0 mini" @click="addPhone">
                  <v-icon small dark color="success">{{$root.icons.action.add}}</v-icon>
                </v-btn>
                <span>Adicionar telefone</span>
              </v-tooltip>
            </v-flex> -->
          </v-layout>
        </v-flex>
        <v-flex
          xs12
          sm12>
          <v-btn
            title="Enviar um novo convite por SMS"
            color="accent"
            :disabled="!validSms"
            :loading="isLoading"
            outlined
            block
            depressed
            @click="submitSms">
            Enviar SMS
          </v-btn>
        </v-flex>
      </v-layout>
    </v-form>

    <v-form
      v-if="letterChecked"
      ref="form"
      v-model="valid"
      lazy-validation>
      <form-address
        submit-label="Enviar carta correio"
        class="pa-0"
        btn-outline
        btn-block
        :items="addresses"
        :investigator="updateAddresses"
        @submit="submitLetter" />
    </v-form>
  </v-container>
</template>

<script>
  import CardChecked from '@/components/ui/CardChecked'
  import validate from '@/mixins/validate'
  import SelectEmails from '@/components/ui/SelectEmails'
  import FieldPhone from '@/components/ui/FieldPhone'
  import FormAddress from '@/components/ui/forms/FormAddress'
  import { mapGetters } from 'vuex'

  export default {
    components: { CardChecked, SelectEmails, FieldPhone, FormAddress },
    mixins: [ validate ],
    data () {
      return {
        newEmail: '',
        newPhones: [{
          code: '',
          number: '',
          type: ''
        }],
        newAddresses: [],
        emails: [],
        phones: [],
        addresses: [],
        validEmail: true,
        validSms: true,
        valid: true,
        emailChecked: false,
        smsChecked: false,
        letterChecked: false,
        updateAddresses: []
      }
    },
    computed: {
      ...mapGetters({
        isLoading: 'app/getLoading',
        case: 'cases/record'
      })
    },
    mounted (){
      if(this.case && this.case.recipient && this.case.recipient.document){
        this.getInvestigator(this.case.recipient.document)
      }
    },
    methods: {
      changeEmail (isChecked) {
        this.emailChecked = isChecked
        if (isChecked) {
          if(this.$refs.formEmail){
            this.$refs.formEmail.reset()
          }
          this.smsChecked = this.letterChecked = false
        }
      },
      setEmail (email) {
        this.newEmail = email.join()
      },
      submitEmail () {
        if (this.$refs.formEmail.validate()) {
          this.$store.commit('app/setLoading', true)
          this
            .$store
            .dispatch('invites/add', { caseId: this.$route.params.caseId, email: this.newEmail })
            .then(this._success)
            .catch(this._failure)
        }
      },
      _success () {
        this.$store.commit('app/setLoading', false)
        this.$root.$emit('snackbar', {show: true, color: 'success', text: 'Convite enviado com sucesso'})
      },
      _failure () {
        this.$store.commit('app/setLoading', false)
        this.$root.$emit('snackbar', {show: true, color: 'error', text: 'Houve um problema no envio, tente novamente'})
      },
      changeSms (isChecked) {
        this.smsChecked = isChecked
        if (isChecked) {
          if(this.$refs.formSms){
            this.$refs.formSms.reset()
          }
          this.emailChecked = this.letterChecked = false
        }
      },
      setPhoneNumber (phone) {
        this.newPhone = phone.join()
      },
      submitSms () {
        if (this.$refs.formSms.validate()) {
          this.$store.commit('app/setLoading', true)
          console.log('submitSms', this.newPhones)

          // fake callback
          setTimeout(() => {
            this._success()
          }, 2000)

          // callback to use
          // this._success()
          // this._failure ()
        }
      },
      changeLetter (isChecked) {
        this.letterChecked = isChecked
        if (isChecked) {
          if(this.$refs.form){
            this.$refs.form.reset()
          }
          this.smsChecked = this.emailChecked = false
        }
      },
      getInvestigator (cpf) {
        this
          .$store
          .dispatch('investigator/contacts', this.$route.params.caseId)
          .then(result => {
            this.emails = result.emails
            this.updateAddresses = result.addresses
            this.phones = result.phones.map(phone => {
              const object = {
                number: phone.replace(/(\+\d{2})(\d{2})(\d{5})(\d+)/, '$1 $2 $3-$4')
              }
              return object
            })
          })
      },
      removePhone (index) {
        this.newPhones.splice(index, 1)
      },
      addPhone () {
        let phone = {
          number: ''
        }
        // a condicao abaixo só é verdadeira se tiver investigator funcinando
        // let options = this.phones.filter(phone => !this.dataItems.phones.includes(phone))
        // this.newPhones.push({ options })
        this.newPhones.push(phone)
      },
      showAddAction (index) {
        // return this.changePhone && this.newPhones.length === parseInt(index) + 1
        return this.newPhones.length === parseInt(index) + 1
      },
      showRemoveAction (index) {
        if (this.newPhones.length === 1) {
          return false
        } else {
          return this.newPhones.length === index + 1
        }
      },
      submitLetter (addresses) {
        console.log(addresses)
        if (this.$refs.form.validate()) {
          this.$store.commit('app/setLoading', true)
          console.log('submitLetter', addresses)

          // fake callback
          setTimeout(() => {
            this._successLetter()
          }, 2000)
        }
      },
      _successLetter () {
        this.$store.commit('app/setLoading', false)
        this.$root.$emit('snackbar', {show: true, color: 'success', text: 'A carta será enviada em até 48 horas'})
        if(this.$refs.form){
          this.$refs.form.reset()
        }
      }
    }
  }
</script>

<style lang="sass" scoped>
  .v-btn.mini
    width: 30px
    height: 30px
    margin-top: 20px
</style>

